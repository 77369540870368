import vendereImage from "../assets/natale/0.png";
import affittareImage from "../assets/natale/1.png";
import vecchiImage from "../assets/natale/2.png";
import domandeImage from "../assets/natale/3.png";
import attivitaImage from "../assets/natale/4.png";
import tvMessage from "../types/TvMessage";

export const tvMessages: tvMessage[] = [
    {
        image: vendereImage,
        title: "Auguri di Buon Natale!",
        message: "Che la magia del Natale riempia la tua casa di gioia e serenità."
    },
    {
        image: affittareImage,
        title: "Feste in famiglia",
        message: "Natale è il momento perfetto per riunirsi con chi ami. Buone feste!"
    },
    {
        image: domandeImage,
        title: "Un regalo speciale per te",
        message: "Quest'anno regala sorrisi e momenti indimenticabili a chi ti sta a cuore."
    },
    {
        image: vecchiImage,
        title: "Natale sotto l'albero",
        message: "Che ogni desiderio espresso sotto l'albero diventi realtà!"
    },
    {
        image: attivitaImage,
         title: "La tua nuova casa ti aspetta!",
        message: "A Natale, regala a te e alla tua famiglia un nuovo inizio!"
    },
];
